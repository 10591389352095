import * as types from './Types'
const initialState = {
    AllCategory: [],
    SingleCategory: {},
    GetMails: [],
    GetSingleMail: {},
    AllSMS: [],
    SingleSMS: {},
    loading: true
}

const mailReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {

        // ************************  Mail Category ******************************* 
        case types.GET_ALL_CATEGORIES:
            return {
                ...state,
                AllCategory: payload,
                loading: false
            }
        case types.GET_SINGLE_CATEGORY:
            return {
                ...state,
                SingleCategory: payload,
                loading: false
            }
        case types.ADD_CATEGORY:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_CATEGORY:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_CATEGORY:
            return {
                ...state,
                loading: false
            }
        // ************************  Mail SMS ******************************* 
        case types.GET_ALL_SMS:
            return {
                ...state,
                AllSMS: payload,
                loading: false
            }
        case types.GET_SINGLE_SMS:
            return {
                ...state,
                SingleSMS: payload,
                loading: false
            }
        case types.ADD_SMS:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_SMS:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_SMS:
            return {
                ...state,
                loading: false
            }
        // ************************  MailConfig ******************************* 
        case types.GET_ALL_MAILCONFIG:
            return {
                ...state,
                GetMails: payload,
                loading: false
            }
        case types.GET_SINGLE_MAILCONFIG:
            return {
                ...state,
                GetSingleMail: payload,
                loading: false
            }
        case types.ADD_MAILCONFIG:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_MAILCONFIG:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_MAILCONFIG:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}
export default mailReducers