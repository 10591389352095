import * as types from './PrivillegeTypes'

const initialState = {
    getAllActivePermission: [],
    allButtonPriviege: [],
    allActiveRoles: [],
    getAllModuleSubmodule: [],
    loading: true,
    errorMSG: ''
}
export const privillegeReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_PERMISSION:
            return {
                ...state,
                getAllActivePermission: payload,
                loading: false
            }
            case types.GET_ALL_BTN_PRIVILEGE:
            return {
                ...state,
                allButtonPriviege: payload,
                loading: false
            }
        case types.GET_ALL_ROLES:
            return {
                ...state,
                allActiveRoles: payload,
                loading: false
            }
        case types.GET_MODULE_SUBMODULE:
            return {
                ...state,
                getAllModuleSubmodule: payload,
                loading: false
            }
        default:
            return state
    }
}
export default privillegeReducers