
export const GET_PCVGV_BODY_TYPE = "GET_PCVGV_BODY_TYPE"
export const GET_BODY_TYPE_LIST = "GET_BODY_TYPE_LIST"
export const GET_ALL_GCVPCV_MAKE = "GET_ALL_GCVPCV_MAKE"
export const GET_ALL_GCVPCV_MODEL = "GET_ALL_GCVPCV_MODEL"
export const GET_ALL_GCVPCV_FUEL = "GET_ALL_GCVPCV_FUEL"
export const GET_ALL_GCVPCV_VARINAT = "GET_ALL_GCVPCV_VARINAT"
export const GET_ALL_GCVPCV_RTO = "GET_ALL_GCVPCV_RTO"
export const GET_GCV_PCV_LEADS = "GET_GCV_PCV_LEADS"
export const STORED_PCV_JOURNEY = "STORED_PCV_JOURNEY"
export const RESET_PCV_JOURNEY = "RESET_PCV_JOURNEY"
export const STORE_QUOTES_REQUEST = "STORE_QUOTES_REQUEST"
export const GET_QUOTES_RESPONSE = "GET_QUOTES_RESPONSE"
export const RESET_GET_QUOTES_RESPONSE = "RESET_GET_QUOTES_RESPONSE"
export const GET_ACTIVE_INURER = "GET_ACTIVE_INURER"
export const NOT_GET_QUOTES = "NOT_GET_QUOTES"
export const SELECTED_INSURARER_DETAILS = "SELECTED_INSURARER_DETAILS"
export const ALL_KYC_RESPONSE = "ALL_KYC_RESPONSE"
export const STORE_PROPOSAL_INFO = "STORE_PROPOSAL_INFO"
export const GET_ALL_STATE = "GET_ALL_STATE"
export const GET_CITY_BASED_STATE = "GET_CITY_BASED_STATE"
export const GET_PROPOSAL_RESPONSE = "GET_PROPOSAL_RESPONSE"
export const DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD"
export const STORE_RIDERS = "STORE_RIDERS"
export const RESET_PERPOSAL_INFO = "RESET_PERPOSAL_INFO"