import * as types from './Types'

const initailState = {
    loadBikeName: [],
    loadBikeModel: [],
    loadBikeFuel: [],
    loadBikeVariant: [],
    loadBikeRTO: [],
    StateName: [],
    CityName: [],
    Relation: [],
    Occupation: [],
    Insurer: [],
    StoreMakeData: {},
    StoreModelData: {},
    StoreFuelData: {},
    StoreVariant: {},
    storeRtoData: {},
    storeYearData: {},
    loading: true
}

export const BikeReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_BIKE_MAKES:
            return {
                ...state,
                loadBikeName: payload,
                loading: false
            }
        case types.GET_ALL_BIKE_MODEL:
            return {
                ...state,
                loadBikeModel: payload,
                loading: false
            }
        case types.GET_FUEL:
            return {
                ...state,
                loadBikeFuel: payload,
                loading: false
            }
        case types.GET_ALL_BIKE_VARIANT:
            return {
                ...state,
                loadBikeVariant: payload,
                loading: false
            }
        case types.GET_ALL_BIKE_RTO:
            return {
                ...state,
                loadBikeRTO: payload,
                loading: false
            }
        // *********************************************************************** for Store  ******
        case types.ADD_BIKE_MAKE:
            return {
                ...state,
                StoreMakeData: payload,
                loading: false
            }
        case types.ADD_BIKE_MODEL:
            return {
                ...state,
                StoreModelData: payload,
                loading: false
            }
        case types.ADD_BIKE_FUEL:
            return {
                ...state,
                StoreFuelData: payload,
                loading: false
            }
        case types.ADD_BIKE_VARIANT:
            return {
                ...state,
                StoreVariant: payload,
                loading: false
            }
        case types.ADD_BIKE_RTO:
            return {
                ...state,
                storeRtoData: payload,
                loading: false
            }
        case types.ADD_BIKE_YEAR:
            return {
                ...state,
                storeYearData: payload,
                loading: false
            }
        // *********************************************************************** for Other  ******
        case types.GET_ALL_STATE:
            return {
                ...state,
                StateName: payload,
                loading: false
            }
        case types.GET_ALL_CITY:
            return {
                ...state,
                CityName: payload,
                loading: false
            }
        case types.GET_ALL_RELATIONSHIP:
            return {
                ...state,
                Relation: payload,
                loading: false
            }
        case types.GET_ALL_OCCUPATIONS:
            return {
                ...state,
                Occupation: payload,
                loading: false
            }
        case types.GET_ALL_INSURER:
            return {
                ...state,
                Insurer: payload,
                loading: false
            }
        default:
            return state
    }
}

export default BikeReducers