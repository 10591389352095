import * as types from './Type'

const initailState = {
    AllLeads: [],
    getProposl: {},
    getQuotes: {},
    // policyData: {},
    paymentBasedOn: {},
    getPayment: [],
    paidSinglePolicy: [],
    loading: true
}
export const LeadReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_LEADS: return {
            ...state,
            AllLeads: payload,
            loading: false
        }
        case types.GET_PROPOSAL_LEADID_BASED: return {
            ...state,
            getProposl: payload,
            loading: false
        }
        case types.GET_QUOTES_LEADID_BASED: return {
            ...state,
            getQuotes: payload,
            loading: false
        }
        case types.GET_PAYMENT_USERID_BASED: return {
            ...state,
            getPayment: payload,
            loading: false
        }
        // case types.GET_POLICY_DETAILS: return {
        //     ...state,
        //     policyData: payload,
        //     loading: false
        // }
        case types.GET_SINGLE_PAID_POLICY: return {
            ...state,
            paidSinglePolicy: payload,
            loading: false
        }
        case types.GET_PAMENT_BASED_ON: return {
            ...state,
            paymentBasedOn: payload,
            loading: false
        }
        default: return state
    }
}
export default LeadReducers