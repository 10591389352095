

export const GET_ALL_BIKE_MAKES = "GET_ALL_BIKE_MAKES"
export const GET_ALL_BIKE_MODEL = "GET_ALL_BIKE_MODEL"
export const GET_FUEL = "GET_FUEL"
export const GET_ALL_BIKE_VARIANT = "GET_ALL_BIKE_VARIANT"
export const GET_ALL_BIKE_RTO = "GET_ALL_BIKE_RTO"

export const ADD_BIKE_MAKE = "ADD_BIKE_MAKE"
export const ADD_BIKE_MODEL = "ADD_BIKE_MODEL"
export const ADD_BIKE_FUEL = "ADD_BIKE_FUEL"
export const ADD_BIKE_VARIANT = "ADD_BIKE_VARIANT"
export const ADD_BIKE_RTO = "ADD_BIKE_RTO"
export const ADD_BIKE_YEAR = "ADD_BIKE_YEAR"

export const GET_ALL_STATE = "GET_ALL_STATE"
export const GET_ALL_CITY = "GET_ALL_CITY"

export const GET_ALL_RELATIONSHIP = "GET_ALL_RELATIONSHIP"
export const GET_ALL_OCCUPATIONS = "GET_ALL_OCCUPATIONS"

export const GET_ALL_INSURER = "GET_ALL_INSURER"
