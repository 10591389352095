import * as types from './Types'
const initialState = {
    getJob: [],

    loading: true
}

const JobSchedulerReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {

        case types.GET_JOBSCHEDULER:
            return {
                ...state,
                getJob: payload,
                loading: false
            }

        default:
            return state
    }
}
export default JobSchedulerReducers