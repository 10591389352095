import * as types from './Types'

const initailState = {
    AllRegion: [],
    ActiveRegions: [],
    AllBranch: [],
    SingleRegion: {},
    SingleBranch: {},
    loading: true
}
export const branchRegionReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_BRANCH:
            return {
                ...state,
                AllBranch: payload,
                loading: false
            }
        case types.GET_SINGLE_BRANCH:
            return {
                ...state,
                SingleBranch: payload,
                loading: false
            }
        case types.ADD_BRANCH:
            return {
                ...state,
                loading: false
            }
            case types.GET_ALL_ACTIVE_RESION:
            return {
                ...state,
                ActiveRegions: payload,
                loading: false
            }
        case types.DELETE_BRANCH:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_BRANCH:
            return {
                ...state,
                loading: false
            }
        /// ************* Region  ******************
        case types.GET_ALL_REGIONS:
            return {
                ...state,
                AllRegion: payload,
                loading: false
            }
        case types.GET_SINGLE_REGION:
            return {
                ...state,
                SingleRegion: payload,
                loading: false
            }
        case types.ADD_REGION:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_REGION:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_REGION:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}
export default branchRegionReducers