import * as types from './Types'

const initialState = { AllOrg: [], ActiveState: [], loading: true }

const OrgReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_ORG:
            return {
                ...state,
                AllOrg: payload,
                loading: false
            }
        case types.GET_SINGLE_ORG:
            return {
                ...state,
                SingleOrg: payload,
                loading: false
            }
        case types.ADD_ORG:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_ORG:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_ORG:
            return {
                ...state,
                loading: false
            }
        case types.GET_ALL_ACT_STATE:
            return {
                ...state,
                ActiveState: payload,
                loading: false
            }
        default:
            return state
    }
}

export default OrgReducers