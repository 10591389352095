
// ********************************************************************** Country

export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY"
export const GET_SINGLE_COUNTRY = "GET_SINGLE_COUNTRY"
export const ADD_COUNTRY = "ADD_COUNTRY"
export const UPDATE_COUNTRY = "UPDATE_COUNTRY"
export const DELETE_COUNTRY = "DELETE_COUNTRY"

// ********************************************************************** State

export const GET_ALL_STATE = " GET_ALL_STATE"
export const GET_SINGLE_STATE = " GET_SINGLE_STATE"
export const ADD_STATE = " ADD_STATE"
export const UPDATE_STATE = " UPDATE_STATE"
export const DELETE_STATE = " DELETE_STATE"

// ********************************************************************** City

export const GET_ALL_CITY = " GET_ALL_CITY"
export const GET_SINGLE_CITY = " GET_SINGLE_CITY"
export const ADD_CITY = " ADD_CITY"
export const UPDATE_CITY = " UPDATE_CITY"
export const DELETE_CITY = " DELETE_CITY"

// ********************************************************************** BASED ON

export const GET_COUNTRY_BASED_STATE = " GET_COUNTRY_BASED_STATE"
export const GET_ACTIVE_COUNTRY = " GET_ACTIVE_COUNTRY"
export const GET_ACTIVE_STATE = " GET_ACTIVE_STATE"