
// ***************************  Mail Category ****************************

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES"
export const GET_SINGLE_CATEGORY = "GET_SINGLE_CATEGORY"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"

// ***************************  MailConfig ******************************* 
export const GET_ALL_MAILCONFIG = "GET_ALL_MAILCONFIG"
export const GET_SINGLE_MAILCONFIG = "GET_SINGLE_MAILCONFIG"
export const ADD_MAILCONFIG = "ADD_MAILCONFIG"
export const UPDATE_MAILCONFIG = "UPDATE_MAILCONFIG"
export const DELETE_MAILCONFIG = "DELETE_MAILCONFIG"

// ***************************  SMSConfig ******************************** 

export const GET_ALL_SMS = "GET_ALL_MAIL_SMS"
export const GET_SINGLE_SMS = "GET_SINGLE_SMS"
export const ADD_SMS = "ADD_SMS"
export const UPDATE_SMS = "UPDATE_SMS"
export const DELETE_SMS = "DELETE_SMS"
