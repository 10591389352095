
// ********************************************************************* Menu
export const GET_ALL_MENUS = "GET_ALL_MENUS"
export const GET_SINGLE_MENU = "GET_SINGLE_MENU"
export const ADD_MENU = "ADD_MENU"
export const UPDATE_MENU = "UPDATE_MENU"
export const DELETE_MENU = "DELETE_MENU"
// ********************************************************************* Sub_Menu
export const GET_ALL_SUB_MENUS = "GET_ALL_SUB_MENUS"
export const GET_SINGLE_SUB_MENU = "GET_SINGLE_SUB_MENU"
export const ADD_SUB_MENU = "ADD_SUB_MENU"
export const UPDATE_SUB_MENU = "UPDATE_SUB_MENU"
export const DELETE_SUB_MENU = "DELETE_SUB_MENU"
export const GET_ALL_ACTIVE_MODULE = "GET_ALL_ACTIVE_MODULE"
// *********************************************************************Permission
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS"
export const GET_SINGLE_PERMISSION = "GET_SINGLE_PERMISSION"
export const ADD_PERMISSION = "ADD_PERMISSION"
export const UPDATE_PERMISSION = "UPDATE_PERMISSION"
export const DELETE_PERMISSION = "DELETE_PERMISSION"
// ********************************************************************* Master Series 
export const GET_ALL_MASTERS_SERIES = "GET_ALL_MASTERS_SERIES"
export const GET_SINGLE_MASTER_SERIES = "GET_SINGLE_MASTER_SERIES"
export const ADD_MASTER_SERIES = "ADD_MASTER_SERIES"
export const UPDATE_MASTER_SERIES = "UPDATE_MASTER_SERIES"
export const DELETE_MASTER_SERIES = "DELETE_MASTER_SERIES"
// ********************************************************************* Fuel
export const GET_ALL_FUEL = "GET_ALL_FUEL"
export const GET_SINGLE_FUEL = "GET_SINGLE_FUEL"
export const ADD_FUEL = "ADD_FUEL"
export const UPDATE_FUEL = "UPDATE_FUEL"
export const DELETE_FUEL = "DELETE_FUEL"
// ********************************************************************* Vehicle Type
export const GET_ALL_VEHICLE = "GET_ALL_VEHICLE"
export const GET_SINGLE_VEHICLE = "GET_SINGLE_VEHICLE"
export const ADD_VEHICLE = "ADD_VEHICLE"
export const UPDATE_VEHICLE = "UPDATE_VEHICLE"
export const DELETE_VEHICLE = "DELETE_VEHICLE"
// ********************************************************************* Insurer
export const GET_ALL_INSURER = "GET_ALL_INSURER"
export const GET_SINGLE_INSURER = "GET_SINGLE_INSURER"
export const ADD_INSURER = "ADD_INSURER"
export const UPDATE_INSURER = "UPDATE_INSURER"
export const DELETE_INSURER = "DELETE_INSURER"
export const GET_ALL_ACTIVE_INSURER = "GET_ALL_ACTIVE_INSURER"
// ********************************************************************* Products
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS"
export const GET_SINGLE_PRODUCTS = "GET_SINGLE_PRODUCTS"
export const ADD_PRODUCTS = "ADD_PRODUCTS"
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS"
export const DELETE_PRODUCTS = "DELETE_PRODUCTS"