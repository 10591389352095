import * as types from './Types'
const initialState = {
    AllMenu: [],
    AllSubMenu: [],
    ActiveModule: [],
    AllRoles: [],
    ActiveRole: [],
    AllTeams: [],
    AllPermission: [],
    AllMasters: [],
    AllQualification: [],
    FuleType: [],
    RtoType: [],
    RtoState: [],
    RtoCity: [],
    VehicleType: [],
    InsurerType: [],
    PreviousInsurer: [],
    ActiveInsurer: [],
    Occupation: [],
    RelationShip: [],
    Products: [],
    AllFinancer: [],
    SingleFinancer: [],
    AllDepartment: [],
    loading: true
}

const menuReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        // ******************************************************* Qualification  
        case types.GET_ALL_QUALIFICATION:
            return {
                ...state,
                AllQualification: payload,
                loading: false
            }
        case types.GET_SINGLE_QUALIFICATION:
            return {
                ...state,
                GetSingleQuali: payload,
                loading: false
            }
        case types.ADD_QUALIFICATION:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_QUALIFICATION:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_QUALIFICATION:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Occupation
        case types.GET_ALL_OCCUPATION:
            return {
                ...state,
                Occupation: payload,
                loading: false
            }
        case types.GET_SINGLE_OCCUPATION:
            return {
                ...state,
                SingleOccupation: payload,
                loading: false
            }
        case types.ADD_OCCUPATION:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_OCCUPATION:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_OCCUPATION:
            return {
                ...state,
                loading: false
            }
        // ******************************************************* Map Role 
        case types.GET_ALL_ROLES:
            return {
                ...state,
                AllRoles: payload,
                loading: false
            }
        case types.GET_SINGLE_ROLE:
            return {
                ...state,
                SingleRole: payload,
                loading: false
            }
        case types.ADD_ROLE:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_ROLE:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_ROLE:
            return {
                ...state,
                loading: false
            }
        // ******************************************************* Map Team
        case types.GET_ALL_ACTIVE_ROLE:
            return {
                ...state,
                ActiveRole: payload,
                loading: false
            }
        case types.GET_ALL_TEAMS:
            return {
                ...state,
                AllTeams: payload,
                loading: false
            }
        case types.GET_SINGLE_TEAM:
            return {
                ...state,
                SingleTeam: payload,
                loading: false
            }
        case types.ADD_TEAM:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_TEAM:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_TEAM:
            return {
                ...state,
                loading: false
            }
        // ******************************************************* RTO
        case types.GET_ALL_RTO:
            return {
                ...state,
                RtoType: payload,
                loading: false
            }
        // State for RTO
        case types.GET_ALL_RTO_STATE:
            return {
                ...state,
                RtoState: payload,
                loading: false
            }
        // City for RTO
        case types.GET_ALL_RTO_CITY:
            return {
                ...state,
                RtoCity: payload,
                loading: false
            }
        case types.GET_SINGLE_RTO:
            return {
                ...state,
                SingleRTO: payload,
                loading: false
            }
        case types.ADD_RTO:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_RTO:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_RTO:
            return {
                ...state,
                loading: false
            }
        // ******************************************************* Active Insurer
        case types.GET_ALL_ACTIVE_INSURER:
            return {
                ...state,
                ActiveInsurer: payload,
                loading: false
            }

        // *******************************************************  RelationShip
        case types.GET_ALL_RELATIONS:
            return {
                ...state,
                RelationShip: payload,
                loading: false
            }
        case types.GET_SINGLE_RELATIONS:
            return {
                ...state,
                SingleRelationShip: payload,
                loading: false
            }
        case types.ADD_RELATIONS:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_RELATIONS:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_RELATIONS:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Previous Insurer
        case types.GET_ALL_PREVIOUS_INSURER:
            return {
                ...state,
                PreviousInsurer: payload,
                loading: false
            }
        case types.GET_SINGLE_PREVIOUS_INSURER:
            return {
                ...state,
                SingleInsurer: payload,
                loading: false
            }
        case types.ADD_PREVIOUS_INSURER:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_PREVIOUS_INSURER:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_PREVIOUS_INSURER:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Financer
        case types.GET_ALL_FINANCER:
            return {
                ...state,
                AllFinancer: payload,
                loading: false
            }
        case types.GET_SINGLE_FINANCER:
            return {
                ...state,
                SingleFinancer: payload,
                loading: false
            }
        case types.ADD_FINANCER:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_FINANCER:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_FINANCER:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Department
        case types.GET_ALL_DEPARTMENT:
            return {
                ...state,
                AllDepartment: payload,
                loading: false
            }
        // case types.GET_SINGLE_FINANCER:
        //     return {
        //         ...state,
        //         SingleFinancer: payload,
        //         loading: false
        //     }
        // case types.ADD_FINANCER:
        //     return {
        //         ...state,
        //         loading: false
        //     }
        // case types.UPDATE_FINANCER:
        //     return {
        //         ...state,
        //         loading: false
        //     }
        // case types.DELETE_FINANCER:
        //     return {
        //         ...state,
        //         loading: false
        //     }
        default:
            return state
    }
}
export default menuReducers