import * as types from './Types'

const initailState = {
    AllCountry: [],
    AllState: [],
    CountryBasedState: [],
    AllCity: [],
    ActiveCountry: [],
    loading: true
}

export const locationReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_COUNTRY:
            return {
                ...state,
                AllCountry: payload,
                loading: false
            }
        case types.ADD_COUNTRY:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_COUNTRY:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_COUNTRY:
            return {
                ...state,
                loading: false
            }
        // *************************************************************** State
        case types.GET_ALL_STATE:
            return {
                ...state,
                AllState: payload,
                loading: false
            }
        case types.ADD_STATE:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_STATE:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_STATE:
            return {
                ...state,
                loading: false
            }
        // *************************************************************** City
        case types.GET_ALL_CITY:
            return {
                ...state,
                AllCity: payload,
                loading: false
            }
        case types.ADD_CITY:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_CITY:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_CITY:
            return {
                ...state,
                loading: false
            }
        // *************************************************************** Based ON
        case types.GET_COUNTRY_BASED_STATE:
            return {
                ...state,
                CountryBasedState: payload,
                loading: false
            }

             // *************************************************************** Active Country
        case types.GET_ACTIVE_COUNTRY:
            return {
                ...state,
                ActiveCountry: payload,
                loading: false
            }
        default:
            return state
    }
}

export default locationReducers