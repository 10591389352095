import * as types from './Types'

const initailState = {
    AllPos: [],
    getSiglePos: {},
    coutPosStatus: {},
    rejectReason: {},
    education: {},
    addressInfo: {},
    aadhaarInfo: {},
    panInfo: {},
    personalInfo: {},
    loadAllCity: [],
    loading: true
}

export const posReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_POS:
            return {
                ...state,
                AllPos: payload,
                loading: false
            }
        case types.GET_SINGLE_POS: return {
            ...state,
            getSiglePos: payload,
            loading: false
        }
        case types.COUNT_POS_STATUS: return {
            ...state,
            coutPosStatus: payload,
            loading: false
        }
        case types.GET_REJECT_REASON: return {
            ...state,
            rejectReason: payload,
            loading: false
        }
        case types.GET_EDUCATION: return {
            ...state,
            education: payload,
            loading: false
        }
        case types.PERSONAL_INFO: return {
            ...state,
            personalInfo: payload,
            loading: false
        }
        case types.PAN_INFO: return {
            ...state,
            panInfo: payload,
            loading: false
        }
        case types.AADHAAR_INFO: return {
            ...state,
            aadhaarInfo: payload,
            loading: false
        }
        case types.ADDRESS_INFO: return {
            ...state,
            addressInfo: payload,
            loading: false
        }
        case types.GET_ALL_CITY: return {
            ...state,
            loadAllCity: payload,
            loading: false
        }
        default:
            return state
    }
}

export default posReducers